import { FetchStatus } from '@tanstack/query-core/src/types'
import {
	Chart as ChartComponent,
	ChartProps as ChartComponentProps,
	color,
} from '@withdiver/components/src/charts/Chart'
import { GaugeChart } from '@withdiver/components/src/charts/GaugeChart'
import { TableChart } from '@withdiver/components/src/charts/TableChart'
import { TextChart } from '@withdiver/components/src/charts/TextChart'
import { Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import { useEffect, useMemo } from 'react'
import {
	ChartType,
	GetChartQuery,
	QueryFilterCompiledQueryArgs,
	useGetChartQuery,
	useGetSharedChartQuery,
} from '../generated/graphql'

interface ChartProps {
	aggregation: string
	chartId: string
	graphQLClient: GraphQLClient
	filters?: QueryFilterCompiledQueryArgs['filters']
	onFetchingStateChange?: (chartId: string, state: FetchStatus) => void
	since?: string
	shareId?: string
	until?: string
}

export function Chart({
	aggregation,
	chartId,
	filters: queryFilters = [],
	graphQLClient,
	onFetchingStateChange,
	since,
	shareId,
	until,
}: ChartProps) {
	const filters = [...queryFilters]
	if (aggregation) {
		filters.push({
			name: 'aggregation',
			value: aggregation,
		})
	}
	if (since && until) {
		filters.push({
			name: 'timerange',
			values: [ since, until ],
		})
	}
	let data: GetChartQuery, fetchStatus, isLoading
	const chartQuery = useGetChartQuery(
		graphQLClient,
		{
			chartId,
			filters,
		}, {
			enabled: !shareId,
			placeholderData: previousData => previousData,
			refetchOnWindowFocus: false,
		},
	)
	const sharedChartQuery = useGetSharedChartQuery(
		graphQLClient,
		{
			chartId,
			filters,
			shareId,
		}, {
			enabled: Boolean(shareId),
			placeholderData: previousData => previousData,
			refetchOnWindowFocus: false,
		},
	)

	if (!shareId) {
		data = chartQuery.data
		fetchStatus = chartQuery.fetchStatus
		isLoading = chartQuery.isLoading
	} else {
		data = sharedChartQuery.data?.shared
		fetchStatus = sharedChartQuery.fetchStatus
		isLoading = sharedChartQuery.isLoading
	}

	useEffect(() => {
		onFetchingStateChange?.(chartId, fetchStatus)
	}, [ chartId, fetchStatus, onFetchingStateChange ])

	const renderSettingsJson = data?.chart?.datasets?.[0]?.render_settings
	const renderSettings = useMemo(() => {
		return JSON.parse(renderSettingsJson ?? 'null')
	}, [ renderSettingsJson ])

	if (isLoading) {
		return (
			<View width="100%" height="100%" alignItems="center" justifyContent="center">
				<Text color="text" fontSize={20}>Loading</Text>
			</View>
		)
	}

	if (!data?.chart) {
		return <>Failed</>
	}

	if (!data.chart.datasets.length) {
		return <View alignSelf="center" width="100%" justifyContent="center">
			<View>No data yet</View>
		</View>
	}

	if (data.chart.type === ChartType.Gauge) {
		const gaugeData = JSON.parse(data.chart.datasets[0].compiledQuery?.result?.rows?.[0] ?? '[]')
		const colorRanges = []
		for (let i = 1; i < gaugeData.length - 1; i++) {
			colorRanges.push({
				lower: Number(gaugeData[i]),
				upper: Number(gaugeData[i + 1] ?? (gaugeData[i] + 1)),
			})
		}
		return (
			<GaugeChart
				colorRanges={colorRanges}
				data={gaugeData[0]}
				renderSettings={renderSettings}
			/>
		)
	}

	if (data.chart.type === ChartType.Table) {
		return (
			<TableChart
				data={{
					columns: data.chart.datasets[0].compiledQuery?.result?.columns ?? [],
					rows: data.chart.datasets[0].compiledQuery?.result?.rows.map(row => JSON.parse(row)) ?? [],
				}}
				renderSettings={renderSettings}
			/>
		)
	}

	if (data.chart.type === ChartType.Text) {
		return (
			<TextChart>
				{JSON.parse(data.chart.datasets[0].compiledQuery?.result?.rows?.[0] ?? '[]')?.[0]}
			</TextChart>
		)
	}

	return (
		<ChartComponent
			data={{
				labels: data.chart.datasets[0].compiledQuery?.result?.rows.map(row => JSON.parse(row)[0]),
				datasets: data.chart.datasets[0].compiledQuery?.result?.columns.slice(1).map((column, index) => {
					if (renderSettings?.columns?.[index + 1]?.visibility === 'hidden') {
						return undefined as any
					}

					return { // For now only support one dataset per chart
						type: data!.chart!.datasets[0].type as ChartComponentProps['data']['datasets'][number]['type'],
						label: column,
						cubicInterpolationMode: 'monotone',
						backgroundColor: color(index) + 'bb',
						borderColor: color(index),
						borderRadius: 3,
						hidden: renderSettings?.columns?.[index + 1]?.visibility === 'hidden',
						pointStyle: 'circle',
						pointRadius: 0,
						pointHoverRadius: 3,
						data: data!.chart!.datasets[0].compiledQuery?.result?.rows.flatMap(row => JSON.parse(row)[index + 1]) ?? [],
					}
				})
					.filter(set => set) ?? [],
			}}
			title={data.chart.title}
			type={data.chart.type as any}
			options={{
				interaction: {
					axis: 'x',
					intersect: false,
					mode: 'nearest',
				},
				plugins: {
					legend: {
						display: true,
						labels: {
							usePointStyle: true,
							boxPadding: 1,
						},
					},
				},
			}}
			renderSettings={renderSettings}
		/>
	)
}
