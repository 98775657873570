import { useParams } from 'react-router-dom'
import { useGetShareQuery } from './generated/graphql'
import { useGraphQLClient } from './useGraphQLClient'

function useShare() {
	const { shareId } = useParams()
	const graphQLClient = useGraphQLClient()
	const {
		isError,
		isPending,
	} = useGetShareQuery(graphQLClient, { shareId })

	return {
		isError,
		isPending,
		shareId,
	}
}

export {
	useShare,
}
