import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import {
	DashboardGroup,
	useCreateDashboardGroupMutation,
	useSaveDashboardGroupMutation,
} from '../../generated/graphql'

interface DashboardGroupModalProps extends ModalProps {
	dashboardGroup?: DashboardGroup
	graphQLClient: GraphQLClient
	organizationId?: string
}

interface DashboardGroupFormValues {
	name: string
}

const Form = styled.form``

export function DashboardGroupModal({
	dashboardGroup,
	graphQLClient,
	organizationId,
	...props
}: DashboardGroupModalProps) {
	const queryClient = useQueryClient()

	const createDashboardGroup = useCreateDashboardGroupMutation(graphQLClient)
	const saveDashboardGroup = useSaveDashboardGroupMutation(graphQLClient)
	const { register, handleSubmit } = useForm<DashboardGroupFormValues>({
		defaultValues: {
			name: dashboardGroup?.name,
		},
	})

	const onHide = props.onHide
	const onSaveDashboardGroup = useCallback(async ({ name }: DashboardGroupFormValues) => {
		try {
			if (!dashboardGroup) {
				await createDashboardGroup.mutateAsync({
					organizationId,
					name,
				})
			}
			if (dashboardGroup) {
				await saveDashboardGroup.mutateAsync({
					dashboardGroupId: dashboardGroup.id,
					name,
				})
			}
			await queryClient.invalidateQueries()
			onHide?.()
		} catch (e) {
			console.error(e)
		}
	}, [ createDashboardGroup, dashboardGroup, onHide, organizationId, queryClient, saveDashboardGroup ])

	return (
		<Modal {...props}>
			<View minWidth="300px" width="30vw" flexGrow={1} flexDirection="column">
				<View
					as={Form}
					flexDirection="column"
					onSubmit={handleSubmit(onSaveDashboardGroup)}
					width="100%"
					gap="20px"
				>
					<FormControl label="Dashboard group name">
						<Input
							{...register('name')}
						/>
					</FormControl>
					<View mt={2}>
						<Button variant="primary" size="medium">
							{Boolean(dashboardGroup) || 'Create dashboard group'}
							{Boolean(dashboardGroup) && 'Save dashboard group'}
						</Button>
					</View>
				</View>
			</View>
		</Modal>
	)
}
