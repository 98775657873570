import React, {
	SyntheticEvent,
	useCallback,
	useState,
} from 'react'
import { Calendar } from 'react-feather'
import { useOnClickOutside } from '../../utils/useOnClickOutside'
import { Input } from '../Input'
import { Option, SelectBody, SelectWrapper } from '../Select'

export interface DatePickerProps<Value> {
	placeholder?: string
	selected?: Value
	disabled?: boolean
	itemToLabel?: (item: Value) => string
}

export const DatePicker = <Value,>({
	itemToLabel,
	placeholder,
	disabled,
	selected,
}: DatePickerProps<Value>) => {
	const [ isOpen, setOpen ] = useState<boolean>(false)
	const ref = useOnClickOutside<HTMLDivElement>(() => setOpen(false))

	const toggleDatePicker = useCallback((e: SyntheticEvent) => {
		!disabled && setOpen(!isOpen)
		e.stopPropagation()
		e.preventDefault()
	}, [ disabled, isOpen ])

	return (
		<SelectWrapper ref={ref}>
			<Input
				icon={<Calendar size={16} onClick={toggleDatePicker}/>}
				onClick={toggleDatePicker}
				placeholder={placeholder}
				readOnly={true}
				value={selected ? (itemToLabel?.(selected) ?? String(selected)) : ''}
			/>
			<SelectBody open={isOpen}>
				<Option>
					CALENDAR PLACEHOLDER
				</Option>
			</SelectBody>
		</SelectWrapper>
	)
}
