import { FullPageCenteredContent, FullPageCenteredSpinner } from '@withdiver/components/src/Dashboard'
import { Text } from '@withdiver/components/src/Text'
import React from 'react'
import { useShare } from '../../useShare'

function SharedPageWrapper({ children }) {
	const {
		isError,
		isPending,
	} = useShare()

	if (isPending) {
		return (
			<FullPageCenteredSpinner/>
		)
	}

	if (isError) {
		return (
			<FullPageCenteredContent>
				<Text
					color="text"
					fontSize="24px"
					opacity="0.5"
					whiteSpace="nowrap"
				>
					Access denied
				</Text>
			</FullPageCenteredContent>
		)
	}

	return children
}

export {
	SharedPageWrapper,
}
