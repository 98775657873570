import { FetchStatus } from '@tanstack/query-core/src/types'
import { useQueryClient } from '@tanstack/react-query'
import Tippy from '@tippyjs/react'
import { AggregationPicker } from '@withdiver/components/src/AggregationPicker'
import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import { Dashboard, DashboardItem } from '@withdiver/components/src/Dashboard'
import MultiSelect from '@withdiver/components/src/inputs/MultiSelect'
import { TopMenu } from '@withdiver/components/src/menus/Top'
import useModal from '@withdiver/components/src/modals/useModal'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React, { useCallback, useState } from 'react'
import { RefreshCw } from 'react-feather'
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from '../../AuthProvider'
import { Chart } from '../../Chart'
import {
	GetDashboardQuery,
	useCreateChartMutation,
	useDeleteChartMutation,
	useGetDashboardQuery,
	useGetSharedDashboardQuery,
	useMoveChartMutation,
} from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'
import { downloadCanvasToImage } from './downloadCanvasToImage'
import { downloadCanvasToPdf } from './downloadCanvasToPdf'
import { QueryEditorModal } from './QueryEditorModal'
import { TimeRangePicker } from './TimeRangePicker'

interface DashboardRouteParams {
	dashboardId: string
	shareId?: string
}

type EditProps = Omit<Parameters<typeof QueryEditorModal>[0], 'graphQLClient'>

const aggregationOptions = {
	minute: 'Minute',
	hour: 'Hour',
	day: 'Day',
	week: 'Week',
	month: 'Month',
	quarter: 'Quarter',
	year: 'Year',
}

function convertSearchFiltersToGraphQlInput(filters: Record<string, string | string[]>) {
	return Object
		.entries(filters)
		.map(([ name, value ]) => ({
			name,
			values: Array.isArray(value!) ? value! : undefined,
			value: !Array.isArray(value!) ? value! : undefined,
		}))
}

const A = styled.a``

function DashboardPage() {
	const [ chartLoading, setChartLoading ] = useState<Record<string, boolean>>({})
	const { dashboardId, shareId } = useParams<keyof DashboardRouteParams>() as DashboardRouteParams
	const { id: organizationId } = useOrganization()
	const auth = useAuth()
	const isLoggedIn = Boolean(auth?.user)
	const editable = isLoggedIn
	const graphQLClient = useGraphQLClient()
	const [ searchParams, setSearchParams ] = useSearchParams()
	const searchFilters: Record<string, string | string[]> = JSON.parse(searchParams.get('filters') ?? '{}') ?? {}
	const showToolbar = searchParams.get('toolbar') !== 'hidden'
	let data: GetDashboardQuery, isLoading: boolean
	const dashboardQuery = useGetDashboardQuery(graphQLClient, {
		dashboardId,
		filters: convertSearchFiltersToGraphQlInput(searchFilters),
	}, {
		enabled: !shareId,
		placeholderData: previousData => previousData,
	})
	const sharedDashboardQuery = useGetSharedDashboardQuery(graphQLClient, {
		dashboardId,
		filters: convertSearchFiltersToGraphQlInput(searchFilters),
		shareId,
	}, {
		enabled: Boolean(shareId),
		placeholderData: previousData => previousData,
	})
	if (shareId) {
		data = sharedDashboardQuery.data?.shared
		isLoading = sharedDashboardQuery.isLoading
	} else {
		data = dashboardQuery.data
		isLoading = dashboardQuery.isLoading
	}
	const deleteChartMutation = useDeleteChartMutation(graphQLClient)
	const createChartMutation = useCreateChartMutation(graphQLClient)
	const moveChartMutation = useMoveChartMutation(graphQLClient)
	const { show } = useModal()
	const queryClient = useQueryClient()

	const onEditClick = useCallback((editProps: EditProps) => {
		return show(modalProps => (
			<QueryEditorModal
				{...editProps}
				graphQLClient={graphQLClient}
				{...modalProps}
			/>
		))
	}, [ graphQLClient, show ])

	const onAggregationClick = useCallback((aggregation: string) => {
		searchParams.set('aggregation', aggregation)
		setSearchParams(searchParams)
	}, [ searchParams, setSearchParams ])

	const onDeleteChartClick = useCallback((chartId: string) => async () => {
		try {
			await deleteChartMutation.mutateAsync({ chartId })
		} finally {
			await queryClient.invalidateQueries({ refetchType: 'all' })
		}
	}, [ deleteChartMutation, queryClient ])

	const onChartDrop = useCallback(async (chartId: string, x: number, y: number) => {
		try {
			await moveChartMutation.mutateAsync({
				chartId,
				dashboardId,
				x,
				y,
			})
		} finally {
			await queryClient.invalidateQueries({ refetchType: 'all' })
		}
	}, [ moveChartMutation, dashboardId, queryClient ])

	const onCreateChart = useCallback(async (chart: any) => {
		try {
			await createChartMutation.mutateAsync({
				dashboardId,
				x: chart.x,
				y: chart.y,
				width: chart.width,
				height: chart.height,
			})
		} finally {
			await queryClient.invalidateQueries({ refetchType: 'all' })
		}
	}, [ createChartMutation, dashboardId, queryClient ])

	const onRefreshDashboardClick = useCallback(async () => {
		await Promise.all([
			queryClient.invalidateQueries({
				fetchStatus: 'idle',
				queryKey: [ 'GetChart' ],
				refetchType: 'active',
			}),
			queryClient.invalidateQueries({
				fetchStatus: 'idle',
				queryKey: [ 'GetSharedChart' ],
				refetchType: 'active',
			}),
		])
	}, [ queryClient ])

	const onFetchingStateChange = useCallback((chartId: string, fetchStatus: FetchStatus) => {
		if (chartLoading[chartId] === (fetchStatus === 'fetching')) {
			return
		}
		setChartLoading((prev) => ({
			...prev,
			[chartId]: fetchStatus === 'fetching',
		}))
	}, [ chartLoading ])

	if (isLoading) {
		return <>Loading!</>
	}

	const aggregation = searchParams.get('aggregation') || 'month'

	const usedCells = data?.dashboard?.charts.reduce((agg, chart) => agg + chart.width * chart.height, 0) || 0
	const emptyCells = data?.dashboard && data.dashboard.columns * data.dashboard.rows - usedCells

	const customFilters = Array
		.from(new Set(data?.dashboard?.referencedFilters.map(f => f.identifier)))
		.filter(f => !['aggregation', 'timerange'].includes(f))

	const dashboardMargin = 10
	const toolbarHeight = 60
	const subtractedDashboardHeight = (showToolbar ? toolbarHeight : dashboardMargin) + dashboardMargin

	return <>
		<TopMenu
			display={showToolbar ? undefined : 'none'}
			height={toolbarHeight}
		>
			<View width={160}>
				<TextLink to="..">← Dashboards</TextLink>
			</View>
			{data?.dashboard?.referencedFilters.some(f => f.identifier === 'aggregation') &&
			<AggregationPicker
				options={aggregationOptions}
				onChange={onAggregationClick}
				value={aggregation}
			/>
			}
			{data?.dashboard?.referencedFilters.some(f => f.identifier === 'timerange') &&
			<View position="relative">
				<TimeRangePicker/>
			</View>
			}
			<View>
				{customFilters.map(filter => {
					const fi = data?.dashboard?.referencedFilters.find(f => f.identifier === filter)
					const items = Object.assign({}, ...(fi?.queryFilter?.compiledQuery?.result?.rows ?? []).map(r => ({
						[JSON.parse(r)[0] as string]: JSON.parse(r).pop() as string,
					})))

					if (!items) {
						return null
					}

					return (
						<MultiSelect
							key={filter}
							items={Object.keys(items)}
							itemToLabel={key => items[key]}
							selected={(searchFilters[filter] as Array<string>) ?? []}
							onChange={(values) => {
								if (!values.length) {
									delete searchFilters[filter]
								} else {
									searchFilters[filter] = values
								}
								searchParams.set('filters', JSON.stringify(searchFilters))
								if (!Object.keys(searchFilters).length) {
									searchParams.delete('filters')
								}
								setSearchParams(searchParams)
							}}
							placeholder={filter}
						/>
					)
				})}
			</View>
			<View justifySelf="flex-end">
				<Tippy content="Refresh all charts">
					<Button
						variant="primary"
						size="icon"
						onClick={onRefreshDashboardClick}
					>
						<RefreshCw size="18"/>
					</Button>
				</Tippy>
			</View>
			{isLoggedIn &&
			<View
				width={160}
				height={30}
				justifyContent="end"
				alignItems="center"
				color="text"
			>
				<View mr={10}>{auth.user?.name}</View>
				<View
					backgroundImage={`url('${auth.user?.gravatar}')`}
					backgroundPosition="center"
					alignItems="center"
					justifyContent="center"
					backgroundSize="cover"
					size="30px"
					border="1px solid currentColor"
					borderRadius="50%"
				/>
			</View>
			}
		</TopMenu>
		<Dashboard
			columns={data?.dashboard?.columns || 0}
			rows={data?.dashboard?.rows || 0}
			height={`calc(100vh - ${subtractedDashboardHeight}px)`}
			borderWidth="20px"
			borderStyle="solid"
			borderColor="currentColor"
			backgroundColor="currentColor"
			editable={editable}
			emptyCells={emptyCells || 0}
			gap={20}
			m={dashboardMargin}
			mt={showToolbar ? 0 : undefined}
			onChartDrop={onChartDrop}
			onCreateChart={onCreateChart}
		>
			{data?.dashboard?.charts.map(chart => (
				<DashboardItem
					key={chart.id}
					x={chart.x}
					y={chart.y}
					chartId={chart.id}
					width={chart.width}
					height={chart.height}
					padding={20}
					backgroundColor="white"
					draggable={editable}
					editable={editable}
					isLoading={chartLoading[chart.id]}
					onEditClick={onEditClick({
						chartId: chart.id,
						label: chart.datasets[0]?.label,
						organizationId,
						query: chart.datasets[0]?.metric.diver_query ?? '',
						queryId: chart.datasets[0]?.metric.id,
						renderSettings: JSON.parse(chart.datasets[0]?.render_settings ?? 'null'),
						sourceId: chart.datasets[0]?.metric.source.id,
						type: chart.datasets[0]?.type,
					})}
					onDeleteChartClick={onDeleteChartClick(chart.id)}
					onDownloadImageClick={downloadCanvasToImage}
					onDownloadPdfClick={downloadCanvasToPdf}
				>
					<Chart
						aggregation={aggregation}
						chartId={chart.id}
						filters={convertSearchFiltersToGraphQlInput(searchFilters)}
						graphQLClient={graphQLClient}
						onFetchingStateChange={onFetchingStateChange}
						since={searchParams.get('since') ?? undefined}
						shareId={shareId}
						until={searchParams.get('until') ?? undefined}
					/>
				</DashboardItem>
			)) || []}
		</Dashboard>
		{isLoggedIn ||
		<View
			position="fixed"
			bottom={0}
			right={[0, '10%', 100]}
			height={60}
			overflow="hidden"
			p={20}
		>
			<Card
				elevation={3}
				pt={1}
				px={2}
				pb={4}
			>
				<Text color="text">
					Created
					{' '}
					<TextLink
						as={A}
						href="https://withdiver.com"
						target="_blank"
						to=""
					>
						withdiver.com
						🫧
					</TextLink>
				</Text>
			</Card>
		</View>
		}
	</>
}

export default DashboardPage
