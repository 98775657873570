import { useQueryClient } from '@tanstack/react-query'
import { AggregationPicker } from '@withdiver/components/src/AggregationPicker'
import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import { DatePicker } from '@withdiver/components/src/inputs/DatePicker'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback, useEffect, useState } from 'react'
import { Clipboard, Plus } from 'react-feather'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import {
	useFetchMyIpMutation,
	useGetOrganizationDashboardGroupsQuery,
	useShareDashboardGroupMutation,
} from '../../generated/graphql'

interface DashboardGroupShareModalProps extends ModalProps {
	dashboardGroupId: string
	graphQLClient: GraphQLClient
	organizationId: string
}

interface DashboardGroupShareFormValues {
	expirationEnabled: boolean
	expiration: string
	name: string
	networkEnabled: boolean
	networkList: string
	theme: 'auto' | 'dark' | 'light'
	tokenEnabled: boolean
}

const Form = styled.form``

export function DashboardGroupShareModal({
	dashboardGroupId,
	graphQLClient,
	organizationId,
	...props
}: DashboardGroupShareModalProps) {
	const queryClient = useQueryClient()
	const [activeShareId, setActiveShareId] = useState('new')

	const {
		data: groups,
		isLoading: isLoadingGroups,
	} = useGetOrganizationDashboardGroupsQuery(graphQLClient, {
		organizationId,
	})
	const fetchMyIp = useFetchMyIpMutation(graphQLClient)
	const shareDashboardGroup = useShareDashboardGroupMutation(graphQLClient)
	const { register, handleSubmit, setValue, watch } = useForm<DashboardGroupShareFormValues>({
		defaultValues: {
			expirationEnabled: false,
		},
	})

	const onAddMyIpClick = useCallback(async () => {
		const response = await fetchMyIp.mutateAsync()
		const ip = response.fetchMyIp?.value
		if (!ip) {
			return
		}
		if (watch('networkList')) {
			setValue('networkList', `${watch('networkList')}, ${ip}/32`)
		} else {
			setValue('networkList', ip + '/32')
		}
	}, [ fetchMyIp, setValue, watch ])

	const onCopySharingLinkClick = useCallback((shareId: string) => async () => {
		const link = `${window.location.origin}/shared/${shareId}`
		await navigator.clipboard.writeText(link)
	}, [])

	const onShareDashboardGroup = useCallback(async (formValues: DashboardGroupShareFormValues) => {
		try {
			const expiration = formValues.expiration
			const network = formValues.networkList.split(', ')
			const auth = []
			if (expiration) {
				auth.push('expiration')
			}
			if (network) {
				auth.push('network')
			}
			const response = await shareDashboardGroup.mutateAsync({
				dashboardGroupId: dashboardGroupId,
				shareId: activeShareId === 'new' ? undefined : activeShareId,
				options: JSON.stringify({
					auth,
					expiration,
					name: formValues.name,
					network,
					theme: formValues.theme,
				}),
			})
			await queryClient.invalidateQueries()
			setActiveShareId(response.shareDashboardGroup.id)
		} catch (e) {
			console.error(e)
		}
	}, [ activeShareId, dashboardGroupId, queryClient, shareDashboardGroup ])

	const dashboardGroup = groups?.me.organization?.dashboardGroups
		.find(group => group.id === dashboardGroupId)
	const shares = dashboardGroup?.shares ?? []
	const activeShare = shares.find(share => share.id === activeShareId)
	if (!shares.find(s => s.id === 'new')) {
		shares.push({ id: 'new' })
	}

	useEffect(() => {
		if (!activeShare) {
			return
		}
		if (activeShare.id === 'new') {
			setValue('expiration', '')
			setValue('name', '')
			setValue('networkList', '')
			setValue('theme', 'auto')
			return
		}
		const options = JSON.parse(activeShare.options) ?? {}
		if (options.expiration) {
			const expiration = options.expiration
			setValue('expiration', expiration.toLocaleString())
		}
		setValue('name', options.name ?? '')
		setValue('networkList', options.network?.join(', ') ?? '')
		setValue('theme', options.theme ?? 'auto')
	}, [ activeShare, setValue ])

	if (isLoadingGroups) {
		return null
	}

	return (
		<Modal {...props}>
			<View
				flexDirection="column"
				flexGrow={1}
				gap="5px"
				minWidth="300px"
				width="30vw"
			>
				<Text
					color="text"
					fontSize="24px"
				>
					{dashboardGroup.name}
				</Text>
				<Text
					color="text"
					fontSize={14}
					opacity="0.5"
				>
					Share the group "{dashboardGroup.name}" here
				</Text>
				{Boolean(shares.length) ||
				<View mt={2}>
					<Button variant="primary">Begin sharing</Button>
				</View>
				}
				{shares.map(share => (
					<Card
						cursor={share.id !== activeShareId ? 'pointer' : undefined}
						onClick={() => setActiveShareId(share.id)}
						p={share.id === activeShareId ? 2 : 1}
						key={share.id}
						flexDirection="column"
					>
						<Text
							color="text"
							fontSize="16px"
							fontWeight="500"
							justifyContent="space-between"
							mb={share.id === activeShareId ? 1 : undefined}
						>
							{share.id === 'new' && activeShareId !== 'new' &&
							<View alignItems="center">
								<Plus size={18}/>
								<View ml={1}/>
								Add sharing link
							</View>
							}
							{share.id === 'new' && activeShareId === 'new' && 'New sharing link'}
							{share.id !== 'new' && JSON.parse(share.options)?.name}
							{share.id !== 'new' && activeShareId === share.id &&
							<Button
								onClick={onCopySharingLinkClick(share.id)}
								type="button"
								variant="success"
							>
								<Clipboard size={16}/>
								<View ml={1}/>
								Copy sharing link
							</Button>
							}
						</Text>
						{share.id === activeShareId &&
						<View
							as={Form}
							flexDirection="column"
							onSubmit={handleSubmit(onShareDashboardGroup)}
							width="100%"
							gap="20px"
						>
							<FormControl label="Name your sharing link">
								<Input
									{...register('name')}
								/>
							</FormControl>
							<View>
								<FormControl
									flexGrow={1}
									label="Allowed networks"
									mr={1}
								>
									<Input
										{...register('networkList')}
									/>
								</FormControl>
								<FormControl
									label="My IP"
								>
									<View mt={1}>
										<TextLink
											as="span"
											onClick={onAddMyIpClick}
											to=""
										>
											Add my IP
										</TextLink>
									</View>
								</FormControl>
							</View>
							<FormControl label="Expiration">
								<DatePicker
									placeholder={watch('expiration')}
								/>
							</FormControl>
							<FormControl label="Theme">
								<AggregationPicker
									onChange={value => setValue('theme', value)}
									options={{
										auto: 'Auto',
										'dark': 'Dark',
										'light': 'Light',
									}}
									value={watch('theme')}
								/>
							</FormControl>
							<View
								alignItems="center"
								justifyContent="space-between"
							>
								<Button variant="primary" size="medium">
									Share dashboard group
								</Button>
								{share.id !== 'new' && activeShareId === share.id &&
								<TextLink
									as="span"
									color="danger"
									to=""
								>
									Delete link and stop sharing
								</TextLink>
								}
							</View>
						</View>
						}
					</Card>
				))}
			</View>
		</Modal>
	)
}
