import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import { ListView } from '@withdiver/components/src/ListView'
import { Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React, { useCallback } from 'react'
import { SubscriptionPlan, useCancelSubscriptionMutation, useChangePlanMutation } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'

const plans = [
	{
		// There might not be a free plan for the commercial release
		id: undefined,
		name: 'Free',
		pricing: 'Free forever',
	},
	{
		id: SubscriptionPlan.Scuba,
		name: 'Scuba',
		pricing: 'Starting at € 399',
	},
	{
		id: SubscriptionPlan.Olympic,
		name: 'Olympic',
		pricing: 'Build your own plan. Starting at € 899',
	},
]

const activePlan = undefined

function Subscription() {
	const { id: organizationId } = useOrganization()
	const graphQLClient = useGraphQLClient()
	const cancelSubscriptionMutation = useCancelSubscriptionMutation(graphQLClient)
	const changePlanMutation = useChangePlanMutation(graphQLClient)

	const onPlanChangeClick = useCallback((plan?: SubscriptionPlan) => async () => {
		if (plan) {
			await changePlanMutation.mutateAsync({
				organizationId,
				plan,
			})
		}
		if (!plan) {
			await cancelSubscriptionMutation.mutateAsync({
				organizationId,
			})
		}
	}, [])
	return (
		<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
			<Text
				color="text"
				display="flex"
				maxWidth={1100}
				px={3}
				fontWeight="bold"
			>
				<View width="30%">Plan</View>
				<View width="70%">Price</View>
			</Text>
			<ListView gap={20}>
				{plans.map(plan => (
					<Card p={3} maxWidth={1100} alignItems="center">
						<Text
							display="block"
							color="text"
							fontSize={3}
							width="30%"
							textOverflow="ellipsis"
							overflow="hidden"
							whiteSpace="nowrap"
						>
							{plan.name}
						</Text>
						<Text
							display="block"
							color="text"
							width="60%"
							textOverflow="ellipsis"
							overflow="hidden"
							whiteSpace="nowrap"
						>
							{plan.pricing}
						</Text>
						<View width="10%">
							<Button
								onClick={onPlanChangeClick(plan.id)}
								size="medium"
								width="100%"
								justifyContent="center"
								variant={plan.id === activePlan ? 'outline' : 'primary'}
								disabled={plan.id === activePlan}
							>
								{plan.id === activePlan &&
								'Active'
								}
								{plan.id !== activePlan &&
								'Upgrade'
								}
							</Button>
						</View>
					</Card>
				))}
			</ListView>
		</View>
	)
}

export default Subscription
