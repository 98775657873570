import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import { Select } from '@withdiver/components/src/inputs/Select'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback, useState } from 'react'
import {
	DashboardGroup,
	Scalars,
	useAttachDashboardGroupMutation,
	useDetachDashboardGroupMutation,
	useGetDashboardDashboardGroupsQuery,
	useGetOrganizationDashboardGroupsQuery,
} from '../../generated/graphql'

interface DashboardGroupsModalProps extends ModalProps {
	dashboardId: Scalars['ID']
	graphQLClient: GraphQLClient
	organizationId: Scalars['ID']
}

export function DashboardGroupsModal({
	dashboardId,
	graphQLClient,
	organizationId,
	...props
}: DashboardGroupsModalProps) {
	const queryClient = useQueryClient()
	const [ selectedGroup, setSelectedGroup ] = useState<DashboardGroup>()
	const { data: dashboardGroups } = useGetDashboardDashboardGroupsQuery(graphQLClient, {
		dashboardId,
	})
	const { data: groups } = useGetOrganizationDashboardGroupsQuery(graphQLClient, {
		organizationId,
	})

	const attachDashboardGroup = useAttachDashboardGroupMutation(graphQLClient)
	const detachDashboardGroup = useDetachDashboardGroupMutation(graphQLClient)

	const onAddGroupClick = useCallback((groupId: string) => async () => {
		await setSelectedGroup()
		try {
			await attachDashboardGroup.mutateAsync({
				dashboardId,
				groupId,
			})
			await queryClient.invalidateQueries({ refetchType: 'all' })
		} catch (e) {
			console.error(e)
		}
	}, [ attachDashboardGroup, dashboardId, queryClient ])

	const onRemoveGroupClick = useCallback((groupId: string) => async () => {
		try {
			await detachDashboardGroup.mutateAsync({
				dashboardId,
				groupId,
			})
			await queryClient.invalidateQueries({ refetchType: 'all' })
		} catch (e) {
			console.error(e)
		}
	}, [ dashboardId, detachDashboardGroup, queryClient ])

	return (
		<Modal {...props}>
			<View minWidth="300px" width="30vw" flexGrow={1} flexDirection="column">
				<View
					flexDirection="column"
					gap="5px"
				>
					{dashboardGroups?.dashboard?.dashboardGroups.map(group => (
						<Card
							alignItems="center"
							justifyContent="space-between"
							padding={1}
						>
							<Text color="text" fontSize="18px">{group.name}</Text>
							<TextLink
								as={Text}
								color="danger"
								onClick={onRemoveGroupClick(group.id)}
								to=""
							>
								Remove
							</TextLink>
						</Card>
					))}
					{dashboardGroups?.dashboard?.dashboardGroups.length === 0 &&
					<Text color="text" fontSize="18px">
						No groups added. Add one below.
					</Text>
					}
					<View mt={3}>
						<Select
							items={groups?.me.organization
								?.dashboardGroups
								// Make sure that already attached groups are not available in the list
								.filter(group => !dashboardGroups?.dashboard
									?.dashboardGroups
									.some(dashboardGroup => group.id === dashboardGroup.id)) ?? []
							}
							itemToLabel={group => group.name}
							onChange={setSelectedGroup}
							selected={selectedGroup}
							placeholder="Group"
						/>
						<Button
							disabled={!selectedGroup}
							ml={2}
							onClick={onAddGroupClick(selectedGroup?.id)}
							variant="primary"
						>
							Add dashboard to group
						</Button>
					</View>
				</View>
			</View>
		</Modal>
	)
}
