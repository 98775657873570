import { Card } from '@withdiver/components/src/Card'
import {
	Dashboard,
	DashboardItem,
	FullPageCenteredSpinner,
} from '@withdiver/components/src/Dashboard'
import { Text, TextLink } from '@withdiver/components/src/Text'
import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import React from 'react'
import { useTheme } from 'styled-components'
import { useGetSharedDashboardsQuery } from '../../../generated/graphql'
import { useGraphQLClient } from '../../../useGraphQLClient'
import { useShare } from '../../../useShare'

function SharedDashboardListPage() {
	const { shareId } = useShare()
	const graphQLClient = useGraphQLClient()
	const { data, isLoading } = useGetSharedDashboardsQuery(graphQLClient, { shareId })
	const theme = useTheme() as Theme

	if (isLoading) {
		return (
			<FullPageCenteredSpinner/>
		)
	}

	return (
		<View>
			<View px={[0, 40]} py={40} display="block" width="100%">
				<View mb={20} flexDirection="column">
					<Text color="text" fontSize={24}>
						{data?.shared.dashboardGroup.name}
					</Text>
					<Text color="text" fontSize={14} opacity="0.5">
						Dashboards
					</Text>
				</View>
				<View
					display="grid"
					gap="20px"
					gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
				>
					{data?.shared.dashboards.map(dashboard => (
						<Card
							borderRadius={20}
							color="text"
							flexDirection="column"
							height="fit-content"
							width="fit-content"
							key={dashboard.id}
							as={TextLink}
							to={dashboard.id}
							p={1}
						>
							{dashboard.name} ({dashboard.chartCount} charts)
							<View mt={2}/>
							<Dashboard
								columns={dashboard.columns}
								rows={dashboard.rows}
								height={200}
								width={300}
								pointerEvents="none"
								backgroundColor={theme.dashboard.backgroundColor}
								gap={5}
								p={10}
							>
								{dashboard.charts.map(chart => {
									return (
										<DashboardItem
											editable={false}
											key={chart.id}
											padding={0}
											height={chart.height}
											width={chart.width}
											x={chart.x}
											y={chart.y}
										/>
									)
								})}
							</Dashboard>
						</Card>
					))}
				</View>
			</View>
		</View>
	)
}

export default SharedDashboardListPage
