import { Card } from '@withdiver/components/src/Card'
import { OverflowActionMenu } from '@withdiver/components/src/menus/OverflowAction'
import { Text, TextLink } from '@withdiver/components/src/Text'
import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import { format, parseISO } from 'date-fns'
import styled from 'styled-components'
import { Invoice, InvoiceStatus, useGetInvoicesQuery, useGetUpcomingInvoiceQuery } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'

const A = styled.a``

function invoiceStatusColor(invoiceStatus: InvoiceStatus): keyof Theme['colors'] {
	switch (invoiceStatus) {
		case InvoiceStatus.Draft:
			return 'info'
		case InvoiceStatus.Open:
			return 'info'
		case InvoiceStatus.Paid:
			return 'success'
		case InvoiceStatus.Uncollectible:
		case InvoiceStatus.Void:
			return 'danger'
	}
}

interface InvoiceCardProps {
	invoice: Invoice
}

function InvoiceCard({ invoice }: InvoiceCardProps) {
	return (
		<Card p={2} flexDirection="row">
			<View flexGrow={1}>
				<View flexDirection="column">
					<Text color="text" fontWeight="700">
						{format(parseISO(invoice.date), 'MMMM yyyy')}
					</Text>
					<Text color="text">
						{invoice.description}
						Subscription
						{' '}
						<Text
							alignSelf="center"
							color={invoiceStatusColor(invoice.status)}
							fontSize={11}
							textTransform="capitalize"
						>
							{invoice.status}
						</Text>
					</Text>
				</View>
			</View>
			<View flexGrow={1}>
				<View flexDirection="column">
					<Text color="text" fontWeight="700">
						Total Due
					</Text>
					<Text color="text">
						{invoice.total}
					</Text>
				</View>
			</View>
			<Text color="text" alignItems="center">
				<OverflowActionMenu>
					{invoice.id &&
					<TextLink
						color="text"
						to={`${invoice.id}`}
					>
						View Invoice
					</TextLink>
					}
					{invoice.invoice_pdf &&
						<TextLink
							as={A}
							color="text"
							href={invoice.invoice_pdf}
							target="_blank"
							to=""
						>
							Download PDF
						</TextLink>
					}
				</OverflowActionMenu>
			</Text>
		</Card>
	)
}

function InvoiceList() {
	const { id: organizationId } = useOrganization()
	const graphQLClient = useGraphQLClient()
	const { data: invoices } = useGetInvoicesQuery(graphQLClient, { organizationId })
	const { data: upcomingInvoice } = useGetUpcomingInvoiceQuery(graphQLClient, { organizationId })

	const invoice = upcomingInvoice?.me.organization?.upcoming_invoice

	return (
		<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
			<Text color="text" fontSize={18}>
				Upcoming invoice
			</Text>
			{Boolean(invoice) ||
			<Card p={2}>
				<Text color="text" fontSize={18} m="auto">
					No upcoming invoice
				</Text>
			</Card>
			}
			{invoice &&
			<InvoiceCard invoice={invoice}/>
			}
			<View/>
			<Text color="text" fontSize={18}>Past invoices</Text>
			{Boolean(invoices?.me.organization?.invoices.length) ||
			<Card p={2}>
				<Text color="text" fontSize={18} m="auto">
					No past invoices
				</Text>
			</Card>
			}
			{invoices?.me.organization?.invoices.map(invoice => (
				<InvoiceCard invoice={invoice}/>
			))}
		</View>
	)
}

export default InvoiceList
