import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import { Select } from '@withdiver/components/src/inputs/Select'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Dashboard, useCreateDashboardMutation, useSaveDashboardMutation } from '../../generated/graphql'

interface DashboardModalProps extends ModalProps {
	dashboard?: Dashboard
	graphQLClient: GraphQLClient
	organizationId?: string
}

interface DashboardFormValues {
	cacheTtl: string
	name: string
	columns: string
	rows: string
}

const cacheOptions = {
	0: 'No cache',
	600: '10 minutes',
	1800: '30 minutes',
	3600: '1 hour',
	86400: '24 hours',
}

const Form = styled.form``

export function DashboardModal({
	dashboard,
	graphQLClient,
	organizationId,
	...props
}: DashboardModalProps) {
	const queryClient = useQueryClient()

	const createDashboard = useCreateDashboardMutation(graphQLClient)
	const saveDashboard = useSaveDashboardMutation(graphQLClient)
	const { register, handleSubmit, setValue, watch } = useForm<DashboardFormValues>({
		defaultValues: {
			cacheTtl: dashboard?.cache_ttl ?? '0',
			columns: dashboard?.columns,
			name: dashboard?.name,
			rows: dashboard?.rows,
		},
	})

	const onHide = props.onHide
	const onSaveDashboard = useCallback(async ({ cacheTtl, name, rows, columns }: DashboardFormValues) => {
		try {
			if (!dashboard) {
				await createDashboard.mutateAsync({
					cacheTtl: Number(cacheTtl),
					organizationId,
					name,
					columns: parseInt(columns),
					rows: parseInt(rows),
				})
			}
			if (dashboard) {
				await saveDashboard.mutateAsync({
					cacheTtl: Number(cacheTtl),
					dashboardId: dashboard.id,
					name,
					columns: parseInt(columns),
					rows: parseInt(rows),
				})
			}
			await queryClient.invalidateQueries()
			onHide?.()
		} catch (e) {
			console.error(e)
		}
	}, [ createDashboard, dashboard, onHide, organizationId, queryClient, saveDashboard ])

	return (
		<Modal {...props}>
			<View minWidth="300px" width="30vw" flexGrow={1} flexDirection="column">
				<View
					as={Form}
					flexDirection="column"
					onSubmit={handleSubmit(onSaveDashboard)}
					width="100%"
					gap="20px"
				>
					<FormControl label="Dashboard name">
						<Input
							{...register('name')}
						/>
					</FormControl>
					<FormControl label="Dashboard columns (x)">
						<Input
							min={1}
							type="number"
							{...register('columns')}
						/>
					</FormControl>
					<FormControl label="Dashboard rows (y)">
						<Input
							min={1}
							type="number"
							{...register('rows')}
						/>
					</FormControl>
					<FormControl label="Data cache">
						<Select
							items={Object.keys(cacheOptions)}
							onChange={value => setValue('cacheTtl', value)}
							itemToLabel={item => cacheOptions[item]}
							placeholder={cacheOptions[watch('cacheTtl')]}
							selected={watch('cacheTtl')}
						/>
					</FormControl>
					<View mt={2}>
						<Button variant="primary" size="medium">
							{Boolean(dashboard) || 'Create dashboard'}
							{Boolean(dashboard) && 'Save dashboard'}
						</Button>
					</View>
				</View>
			</View>
		</Modal>
	)
}
